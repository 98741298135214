var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: "spinner-wrapper spinner-" + _vm.size }, [
    _vm.msg != "" ? _c("p", [_vm._v(_vm._s(_vm.msg))]) : _vm._e(),
    _c(
      "div",
      { staticClass: "spinner" },
      [_c("b-spinner", { attrs: { variant: "primary" } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }