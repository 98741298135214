var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "22",
        height: "15",
        viewBox: "0 0 22 15",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M2.24599 0.0129395C1.94101 0.0136888 1.64305 0.104116 1.38906 0.272949L10.489 8.89307C10.5732 8.98283 10.6748 9.05436 10.7877 9.10327C10.9007 9.15218 11.0225 9.17725 11.1455 9.17725C11.2686 9.17725 11.3903 9.15218 11.5032 9.10327C11.6161 9.05436 11.7178 8.98283 11.802 8.89307L20.885 0.272949C20.6303 0.104117 20.3316 0.0137089 20.026 0.0129395H2.24599ZM0.733053 1.12402C0.66804 1.30466 0.634248 1.49503 0.633078 1.68701V12.887C0.631467 13.2115 0.721958 13.5298 0.894064 13.8049L7.32607 7.38208L0.733053 1.12402ZM21.542 1.12402L14.9671 7.37012L21.381 13.802C21.5531 13.5269 21.6436 13.2086 21.642 12.884V1.68408C21.6409 1.4921 21.6071 1.30172 21.542 1.12109V1.12402ZM14.1851 8.11597L12.542 9.67407C12.1645 10.0311 11.6647 10.23 11.145 10.23C10.6254 10.23 10.1256 10.0311 9.74807 9.67407L8.10708 8.125L1.74306 14.4709C1.90385 14.5269 2.07283 14.5556 2.24306 14.5559H20.023C20.1949 14.5559 20.3655 14.5272 20.528 14.4709L14.1851 8.11597Z",
          fill: "#333333",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }