<template>
  <div :class="'spinner-wrapper spinner-'+size">
      <p v-if="msg != ''">{{msg}}</p>
      <div class="spinner"><b-spinner variant="primary" /></div>
  </div>
</template>

<script>
export default {
    name: 'spinner',
    components:
    {
    },
    props: {
      msg: {
        type: String,
        default: ''
      },
      size:
        {
          type: String,
          default: 'md'
        }
    },
}
</script>

<style lang="scss">
  .spinner-wrapper
  {
    display:block;
    width:100%;
    text-align:center;
    margin:1rem auto;
    p
    {
      //font-size: 13px;
    }
    .spinner-border
    {
      width:4rem !important;
      height:4rem !important;
    }
    &.spinner-sm .spinner-border
    {
      margin:0 auto;
      width:1.5rem !important;
      height:1.5rem !important;
    }
  }
</style>