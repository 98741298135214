var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dcLogin" },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { sm: "12", lg: "6" } }, [
                _c("h4", { staticClass: "black mt-2" }, [
                  _vm._v("Entra con il tuo codice e prenota il viaggio"),
                ]),
                _c("br"),
                _c("p", [
                  _c("ul", { staticClass: "ml-3" }, [
                    _c("li", [
                      _vm._v(
                        "\n                            Potrai effettuare il tuo viaggio entro la data di scadenza del voucher. Controlla la data esatta nella email che ti abbiamo inviato a seguito dell'acquisto.\n                        "
                      ),
                    ]),
                    _c("li", [
                      _vm._v(
                        "\n                          Controlla, prima di prenotare, se la camera che stai acquistando è rimborsabile o non-rimborsabile.\n                        "
                      ),
                    ]),
                    _c("li", [
                      _vm._v(
                        "\n                          Tutte le disponibilità che vedrai sono reali e confermate senza necessità di telefonate o verifiche con la struttura.\n                        "
                      ),
                    ]),
                    _c("li", [
                      _vm._v(
                        "\n                          Per ogni camera disponibile sono specificati i servizi inclusi.\n                        "
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c(
                "b-col",
                { attrs: { sm: "12", lg: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        state:
                          _vm.validateLoginSalabam() || _vm.validateLoginPin(),
                      },
                    },
                    [
                      _c("div", { staticClass: "loginBlockUp" }, [
                        _c(
                          "div",
                          { staticClass: "customInputBlock titleBlock" },
                          [
                            _c(
                              "div",
                              { staticClass: "titleIcon" },
                              [_c("SvgMail")],
                              1
                            ),
                            _c("h6", { staticClass: "titleText" }, [
                              _vm._v(
                                "\n                                Hai ricevuto il codice via email?\n                                "
                              ),
                              _c("br"),
                              _c("small", [
                                _vm._v(
                                  "Fai login usando email e codice a 7 cifre indicati nell'email"
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "customInputBlock" },
                          [
                            _c("SvgInfoCircle", {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.top",
                                  value:
                                    "L'indirizzo email da usare è quello a cui abbiamo inviato l'email con il codice del voucher",
                                  expression:
                                    "'L\\'indirizzo email da usare è quello a cui abbiamo inviato l\\'email con il codice del voucher'",
                                  modifiers: { hover: true, top: true },
                                },
                              ],
                              staticClass: "svgInfo cursor-pointer",
                            }),
                            _c("b-form-input", {
                              ref: "email",
                              attrs: {
                                required: true,
                                type: "email",
                                placeholder: "Email",
                              },
                              model: {
                                value: _vm.email,
                                callback: function ($$v) {
                                  _vm.email = $$v
                                },
                                expression: "email",
                              },
                            }),
                            _c("span", { staticClass: "formError" }),
                            _c("span", {
                              staticClass: "formClear",
                              on: {
                                click: function ($event) {
                                  return _vm.clearField("email")
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "customInputBlock" },
                          [
                            _c("SvgInfoCircle", {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.top",
                                  value:
                                    "Se non vedi il codice Salabam già inserito nel form di questa pagina recuperalo nella email che abbiamo inviato al momento dell'acquisto",
                                  expression:
                                    "'Se non vedi il codice Salabam già inserito nel form di questa pagina recuperalo nella email che abbiamo inviato al momento dell\\'acquisto'",
                                  modifiers: { hover: true, top: true },
                                },
                              ],
                              staticClass: "svgInfo cursor-pointer",
                            }),
                            _c("b-form-input", {
                              ref: "salabam",
                              attrs: {
                                required: true,
                                type: "text",
                                placeholder: "Es: IA2B8C4",
                                "data-e2e": "salabamInput",
                              },
                              model: {
                                value: _vm.salabam,
                                callback: function ($$v) {
                                  _vm.salabam = $$v
                                },
                                expression: "salabam",
                              },
                            }),
                            _c("span", { staticClass: "formError" }),
                            _c("span", {
                              staticClass: "formClear",
                              on: {
                                click: function ($event) {
                                  return _vm.clearField("salabam")
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        this.$config.guiSettings.giftCardsEnabled
                          ? _c("div", [
                              _c("br"),
                              _c("p", { staticClass: "text-center" }, [
                                _c("small", [_vm._v("oppure...")]),
                              ]),
                              _c("br"),
                              _c(
                                "div",
                                { staticClass: "customInputBlock titleBlock" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "titleIcon" },
                                    [_c("SvgGift")],
                                    1
                                  ),
                                  _c("h6", { staticClass: "titleText" }, [
                                    _vm._v(
                                      "\n                                  Hai una gift card con il codice a 16 cifre?\n                                  "
                                    ),
                                    _c("br"),
                                    _c("small", [
                                      _vm._v(
                                        "Fai login usando il codice che trovi nel retro della card"
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "customInputBlock" },
                                [
                                  _c("SvgInfoCircle", {
                                    directives: [
                                      {
                                        name: "b-popover",
                                        rawName: "v-b-popover.hover.top",
                                        value:
                                          "Fai login usando il codice che trovi nel retro della card",
                                        expression:
                                          "'Fai login usando il codice che trovi nel retro della card'",
                                        modifiers: { hover: true, top: true },
                                      },
                                    ],
                                    staticClass: "svgInfo cursor-pointer",
                                  }),
                                  _c("b-form-input", {
                                    ref: "pin",
                                    attrs: {
                                      required: true,
                                      type: "text",
                                      placeholder: "Es: 8816 0471 0432 6700",
                                      "data-e2e": "salabamInput",
                                    },
                                    on: { input: _vm.formatPin },
                                    model: {
                                      value: _vm.pin,
                                      callback: function ($$v) {
                                        _vm.pin = $$v
                                      },
                                      expression: "pin",
                                    },
                                  }),
                                  _c("span", { staticClass: "formError" }),
                                  _c("span", {
                                    staticClass: "formClear",
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearField("pin")
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("br"),
                              _c("p", { staticClass: "text-center" }, [
                                _c(
                                  "small",
                                  [
                                    _vm._v(
                                      "\n                            Sei interessato ad acquistare una gift card?\n                            "
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: { name: "gift-cards" },
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                Scopri di più!\n                            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _c("br"),
                        _c("div", { staticClass: "checkboxes" }, [
                          _c(
                            "div",
                            { staticClass: "customCheckboxBlock" },
                            [
                              _c("b-form-checkbox", {
                                ref: "checkboxPrivacy",
                                attrs: {
                                  required: "",
                                  type: "checkbox",
                                  name: "checkboxPrivacy",
                                  id: "checkboxPrivacy",
                                },
                                model: {
                                  value: _vm.checkboxPrivacy,
                                  callback: function ($$v) {
                                    _vm.checkboxPrivacy = $$v
                                  },
                                  expression: "checkboxPrivacy",
                                },
                              }),
                              _c(
                                "label",
                                { attrs: { for: "checkboxPrivacy" } },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "secondary",
                                      attrs: {
                                        href: _vm.privacyPolicyUrl,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "Ho preso visione della privacy policy e la accetto"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "customCheckboxBlock" },
                            [
                              _c("b-form-checkbox", {
                                ref: "checkboxTerms",
                                attrs: {
                                  required: "",
                                  type: "checkbox",
                                  name: "checkboxTerms",
                                  id: "checkboxTerms",
                                },
                                model: {
                                  value: _vm.checkboxTerms,
                                  callback: function ($$v) {
                                    _vm.checkboxTerms = $$v
                                  },
                                  expression: "checkboxTerms",
                                },
                              }),
                              _c(
                                "label",
                                { attrs: { for: "checkboxTerms" } },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "nounderline",
                                      attrs: {
                                        to: { name: "terms" },
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "secondary" }, [
                                        _vm._v(
                                          "Ho preso visione delle condizioni d'uso e le accetto"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("br"),
                        _c(
                          "div",
                          { staticClass: "loginBlockLogin" },
                          [
                            !_vm.loading
                              ? _c(
                                  "div",
                                  { staticClass: "loginButton" },
                                  [
                                    _vm.recaptchaEnabled
                                      ? _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              disabled: !(
                                                _vm.validateLoginSalabam() ||
                                                _vm.validateLoginPin()
                                              ),
                                            },
                                            on: { click: _vm.recaptcha },
                                          },
                                          [_vm._v("ACCEDI")]
                                        )
                                      : _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              disabled: !(
                                                _vm.validateLoginSalabam() ||
                                                _vm.validateLoginPin()
                                              ),
                                            },
                                            on: { click: _vm.login },
                                          },
                                          [_vm._v("ACCEDI")]
                                        ),
                                  ],
                                  1
                                )
                              : _c("Spinner"),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }