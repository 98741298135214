<template>
    <div class="dcLogin">
        <b-container>
            <b-row>
                <b-col sm="12" lg="6">
                    <h4 class="black mt-2">Entra con il tuo codice e prenota il viaggio</h4>
                    <br>
                    <p>
                        <ul class="ml-3">
                            <li>
                                Potrai effettuare il tuo viaggio entro la data di scadenza del voucher. Controlla la data esatta nella email che ti abbiamo inviato a seguito dell'acquisto.
                            </li>
                            <li>
                              Controlla, prima di prenotare, se la camera che stai acquistando è rimborsabile o non-rimborsabile.
                            </li>
                            <li>
                              Tutte le disponibilità che vedrai sono reali e confermate senza necessità di telefonate o verifiche con la struttura.
                            </li>
                            <li>
                              Per ogni camera disponibile sono specificati i servizi inclusi.
                            </li>
                        </ul>
                    </p>
                </b-col>
                <b-col sm="12" lg="6">
                    <b-form-group :state="validateLoginSalabam() || validateLoginPin()">
                        
                        <div class="loginBlockUp">
                            <div class="customInputBlock titleBlock">
                                <div class="titleIcon">
                                    <SvgMail />
                                </div>
                                <h6 class="titleText">
                                    Hai ricevuto il codice via email?
                                    <br>
                                    <small>Fai login usando email e codice a 7 cifre indicati nell'email</small>
                                </h6>
                            </div>

                            <div class="customInputBlock">
                                <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'L\'indirizzo email da usare è quello a cui abbiamo inviato l\'email con il codice del voucher'" />
                                <b-form-input ref="email" :required="true" v-model="email" type="email" placeholder="Email" />
                                <span class="formError"></span>
                                <span class="formClear" @click="clearField('email')"></span>
                            </div>

                            <div class="customInputBlock">
                              <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Se non vedi il codice Salabam già inserito nel form di questa pagina recuperalo nella email che abbiamo inviato al momento dell\'acquisto'" />
                              <b-form-input ref="salabam" :required="true" v-model="salabam" type="text" placeholder="Es: IA2B8C4" data-e2e="salabamInput"/>
                              <span class="formError"></span>
                              <span class="formClear" @click="clearField('salabam')"></span>
                            </div>


                            <div v-if="this.$config.guiSettings.giftCardsEnabled"><!-- codice di prova: 3424615233546047 -->

                              <br>
                              <p class="text-center"><small>oppure...</small></p>
                              <br>

                              <div class="customInputBlock titleBlock">
                                  <div class="titleIcon">
                                      <SvgGift />
                                  </div>
                                  <h6 class="titleText">
                                      Hai una gift card con il codice a 16 cifre?
                                      <br>
                                      <small>Fai login usando il codice che trovi nel retro della card</small>
                                  </h6>
                              </div>

                              <div class="customInputBlock">
                                <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Fai login usando il codice che trovi nel retro della card'" />
                                <b-form-input @input="formatPin" ref="pin" :required="true" v-model="pin" type="text" placeholder="Es: 8816 0471 0432 6700" data-e2e="salabamInput"/>
                                <span class="formError"></span>
                                <span class="formClear" @click="clearField('pin')"></span>
                              </div>

                              <br>

                              <p class="text-center">
                                <small>
                                Sei interessato ad acquistare una gift card?
                                <router-link :to="{name: 'gift-cards'}" target="_blank">
                                    Scopri di più!
                                </router-link>
                                </small>
                              </p>

                            </div> <!-- if giftCardEnabled -->

                            <br>

                            <div class="checkboxes">
                                <div class="customCheckboxBlock">
                                    <b-form-checkbox v-model="checkboxPrivacy" ref="checkboxPrivacy" required type="checkbox" name="checkboxPrivacy" id="checkboxPrivacy" />
                                    <label for="checkboxPrivacy">
                                        <a :href="privacyPolicyUrl" target="_blank" class="secondary">Ho preso visione della privacy policy e la accetto</a>
                                    </label>
                                </div>

                                <div class="customCheckboxBlock">
                                    <b-form-checkbox v-model="checkboxTerms" ref="checkboxTerms" required type="checkbox" name="checkboxTerms" id="checkboxTerms" />
                                    <label for="checkboxTerms">
                                        <router-link :to="{name: 'terms'}" target="_blank" class="nounderline">
                                            <span class="secondary">Ho preso visione delle condizioni d'uso e le accetto</span>
                                        </router-link>
                                    </label>
                                </div>
                            </div>

                            <br>

                            <div class="loginBlockLogin">
                                <div class="loginButton" v-if="!loading">
                                    <b-button v-if="recaptchaEnabled" :disabled="!(validateLoginSalabam() || validateLoginPin())" @click="recaptcha">ACCEDI</b-button>
                                    <b-button v-else :disabled="!(validateLoginSalabam() || validateLoginPin())" @click="login">ACCEDI</b-button>
                                </div>
                                <Spinner v-else />
                            </div>
                        </div>

                    </b-form-group>

                    <!--
                    <div v-if="data == '' || data == undefined">
                        C'è stato un problema, devi accedere dal portale Jointly
                    </div>
                    
                    <div v-else>
                        Accesso in corso...
                        <br><br>
                        <Spinner v-if="loading" />
                    </div>
                    -->
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Api from '../../api'
import Spinner from '../atoms/spinner'
import SvgInfoCircle from './../svg/info-circle'
import SvgMail from './../svg/mail'
import SvgGift from './../svg/gift'

export default {
    name: 'login-salabam-code',
    metaInfo: {
      title: 'Usa il tuo voucher Salabam',
      meta: [
        { vmid: 'metaDescription', name: 'Description', content: 'Prenota online con la sicurezza del double check, della white list e della nostra assistenza' },
        { vmid: 'dcDescription', name: 'DC.description', lang: 'it', content: 'Prenota online con la sicurezza del double check, della white list e della nostra assistenza' },
      ],
    },
    components:
    {
        Spinner, SvgInfoCircle, SvgMail, SvgGift,
    },
    props:
    {
        salabamUrl: {
          type: String,
          default: '',
        },
        emailUrl: {
          type: String,
          default: '',
        }
    },
    data()
    {
        return {
            //data: '',
            loading: false,
            email: '',
            salabam: '',
            pin: '',
            checkboxTerms: false,
            checkboxPrivacy: false,
            recaptchaEnabled: Vue.prototype.$config.recaptcha.enabled,
            recaptchaToken: '',
            privacyPolicyUrl: Vue.prototype.$config.site.urlPrivacy,
        }
    },
    mounted()
    {
        if(Vue.prototype.$config.recaptcha.enabled)
        {
            Vue.use(VueReCaptcha, { siteKey: Vue.prototype.$config.recaptcha.siteKey })
        }

        if(this.salabamUrl !== '') {
            this.salabam = this.salabamUrl
        }
        if(this.emailUrl !== '') {
            this.email = this.emailUrl
        }

        if(Object.keys(this.$route.params).length !== 0)
        {
            if(this.$route.params.salabamUrl !== '') {
                this.salabam = this.$route.params.salabamUrl
            }
            if(this.$route.params.emailUrl !== '') {
                this.email = this.$route.params.emailUrl
            }
        }
    },
    methods:
    {
        async recaptcha() {
            this.loading = true
            // (optional) Wait until recaptcha has been loaded.
            await this.$recaptchaLoaded()
            // Execute reCAPTCHA with action "login".
            const token = await this.$recaptcha('login')
            // Do stuff with the received token.
            this.recaptchaToken = token
            this.login()
        },
        formatPin()
        {
            let oldPin = (this.pin).replace(/\s/g,''),
                newPin = (oldPin.match(/.{1,4}/g)).join(' ')
                ;
            

            this.pin = newPin
        },
        clearField(ref)
        {
            this[ref] = ''
            this.$refs[ref].focus()
            this.validateLoginPin()
            this.validateLoginSalabam()
        },
        validateLoginPin()
        {
            if(Object.keys(this.$refs).length == 0) return false
            return this.mixinValidateFields([
                {
                    ref: this.$refs.pin,
                    type: 'pin',
                    value: this.pin,
                },
                {
                    ref: this.$refs.privacy,
                    type: 'checkbox',
                    value: this.checkboxPrivacy,
                },
                {
                    ref: this.$refs.terms,
                    type: 'checkbox',
                    value: this.checkboxTerms,
                }                
            ])
        },
        validateLoginSalabam()
        {
            if(Object.keys(this.$refs).length == 0) return false
            return this.mixinValidateFields([
                {
                    ref: this.$refs.salabam,
                    type: 'salabam',
                    value: this.salabam,
                },
                {
                    ref: this.$refs.email,
                    type: 'email',
                    value: this.email,
                    optional: true,
                },
                {
                    ref: this.$refs.privacy,
                    type: 'checkbox',
                    value: this.checkboxPrivacy,
                },
                {
                    ref: this.$refs.terms,
                    type: 'checkbox',
                    value: this.checkboxTerms,
                }
            ])
        },
        login()
        {
            let _this = this,
                params = {}

            if(_this.validateLoginSalabam())
            {
                params = {
                    confirmcode: _this.salabam,
                    email: _this.email,
                    recaptcha: _this.recaptchaToken,
                }
            }

            else if(_this.validateLoginPin())
            {
                params = {
                    pinCode: _this.pin,
                    recaptcha: _this.recaptchaToken,
                }
            }

            _this.loading = true

            Api.loginSalabam(params)
            .then((results) => {
                if(results !== false)
                {
                    this.mixinSendMutation('setUnlockUpselling',false)
                    this.mixinSendMutation('setActiveBand',results.salabam.band)
                    this.mixinSendMutation('setNights',results.salabam.nights)

                    let fbEventName = (params.pinCode)? 'LoginPinCode' : 'LoginSalabamCode'

                    if(window.LogRocket) window.LogRocket.track('loggedIn')
                    if(window.LogRocket) window.LogRocket.track(fbEventName)

                    if(window.fbq) window.fbq('trackCustom', fbEventName, {
                      content_ids: [results.salabam.boxset_id],
                      content_type: 'product',
                      value: results.salabam.public_price,
                      currency: 'EUR'
                    });


                    window.location.href = "/?token=" + results.token

                }
                else
                {
                    _this.loading = false
                }
            })
        }
    },
}
</script>


<style lang="scss">

    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../scss/_custom.scss';

    .dcLogin
    {
        display:block;
        width:100%;
        max-width:1200px;
        margin:0 auto;
        margin-top:4rem;
        margin-bottom:6rem;
        @include media-breakpoint-down(sm)
        {
            padding-top:2rem;
        }
        ul
        {
            display:block;
            margin-top:1rem;
        }
        li
        {
            list-style:disc;
            margin-top:1rem;
        }
        .loginBlockUp
        {
            margin:auto;
            padding-bottom:2rem;
            max-width: 420px;
        }
        .checkboxes
        {
            margin:auto;
            max-width: 320px;
        }
        .loginBlockLogin
        {
            display:flex;
            min-width:100%;
            .loginDc
            {
                flex:1;
            }
            .loginButton
            {
                display:block;
                min-width:100%;
                //min-width: 160px;
                //margin-left: 2rem;
            }
            button
            {
                display: block;
                width:100%;
            }
        }

        .titleBlock
        {
            display:flex;
            align-items:flex-start;
            flex-wrap:nowrap;
            .titleIcon
            {
                width:24px;
                height:24px;
                margin-right:14px;
                svg
                {
                    width:100%;
                    height:auto;
                    *
                    {
                        fill:$black;
                    }
                }
            }
            .titleText
            {
                color:$black;
                text-align:left;
                margin-bottom:1rem;
            }
        }
    }
</style>