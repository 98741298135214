<template>
<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.208 0.915039C9.87421 0.915039 7.59279 1.60709 5.65229 2.90369C3.71179 4.20029 2.19936 6.04317 1.30625 8.19934C0.413131 10.3555 0.179454 12.7281 0.63476 15.0171C1.09007 17.3061 2.21391 19.4086 3.86417 21.0588C5.51443 22.7091 7.61699 23.8329 9.90596 24.2882C12.1949 24.7435 14.5675 24.5099 16.7237 23.6168C18.8799 22.7237 20.7228 21.2113 22.0194 19.2708C23.316 17.3303 24.008 15.0488 24.008 12.715C24.008 9.58541 22.7648 6.58402 20.5519 4.37109C18.339 2.15816 15.3376 0.915039 12.208 0.915039ZM11.846 5.89001C12.2475 5.89211 12.6319 6.05224 12.916 6.33582C13.2002 6.6194 13.3611 7.0036 13.364 7.40503C13.3559 7.81487 13.1873 8.20509 12.8945 8.49194C12.6016 8.77879 12.2079 8.93931 11.798 8.93896C11.401 8.92937 11.0233 8.76567 10.7449 8.48254C10.4664 8.19942 10.309 7.8191 10.306 7.422C10.3128 7.01641 10.4774 6.62947 10.765 6.34338C11.0526 6.0573 11.4404 5.89465 11.846 5.89001ZM14.865 19.538H9.55003V17.2729H11.05V12.7H9.55003V10.421H13.35V17.254H14.861L14.865 19.538Z" :fill="fill"/>
</svg>
</template>

<script>
export default {
  props:{
    fill: {
      type: String,
      default: '#333333',
    }
  },
}
</script>